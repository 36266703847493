import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template ({ data: { academics, activities, skills, experience } }) {
  const experienceMap = experience.edges
    .reduce((acc, {node}) => {
      if (acc[node.frontmatter.name]) {
        acc[node.frontmatter.name].push(node);
      } else {
        acc[node.frontmatter.name] = [node];
      }

      return acc;
    }, {})
  ;

  return (
    <Layout
      title={`Resume`}
      keywords={[`resume`, `cv`, `jspizziri`]}
      description="I'm a business forward technologist who loves solving problems. Check out some of my experience."
    >
      <Container id="resume">
        <Row>
          <Col sm={12}>
            <h2 className="font-weight-bold text-uppercase">
              Experience
            </h2>
            <hr/>
          </Col>
          {Object.keys(experienceMap)
            .map((name) => {
              const nodes = experienceMap[name];
              return (
                <React.Fragment key={name}>
                <Col xs={12} className="h4 text-left font-weight-bold font-italic text-muted mr-4">
                  {nodes[0].frontmatter.name}
                </Col>
                {nodes.map((node, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Col xs={12} sm={6} className="font-weight-light font-italic text-muted small">{node.frontmatter.position}</Col>
                      <Col xs={12} sm={6} className='text-left text-sm-right font-weight-light font-italic text-muted small'>
                        {node.frontmatter.startedAt} - {node.frontmatter.endedAt ? node.frontmatter.endedAt : 'Present'}
                      </Col>
                      <Col xs={12} className="font-weight-light mt-2" dangerouslySetInnerHTML={{ __html: node.html }}>
                      </Col>
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
              );
          })}

          <Col sm={12}>
            <br/>
            <h2 className="font-weight-bold text-uppercase">
              Academics & Honors
            </h2>
            <hr/>
          </Col>
          {academics.edges.map(({node}, i) => {
            return <React.Fragment key={i}>
              <Col sm={6} className='text-left'>
                <h4 className="font-weight-bold font-italic text-muted">
                  {node.frontmatter.name}
                </h4>
              </Col>
              <Col sm={6} className='text-left text-sm-right font-weight-light font-italic text-muted small'>
                {node.frontmatter.endedAt}
              </Col>
              <Col sm={12} className="font-weight-light mt-2" dangerouslySetInnerHTML={{ __html: node.html }}>
              </Col>
            </React.Fragment>
          })}

          <Col sm={12}>
            <br/>
            <h2 className="font-weight-bold text-uppercase">
              Technical Skillset
            </h2>
            <hr/>
          </Col>
          {skills.edges.map(({node}, i) => {
            return <Col
              key={i}
              sm={12}
              className="font-weight-light mt-2"
              dangerouslySetInnerHTML={{ __html: node.html }}
            />
          })}

          <Col sm={12}>
            <br/>
            <h2 className="font-weight-bold text-uppercase">
              Activities & Interests
            </h2>
            <hr/>
          </Col>
          {activities.edges.map(({node}, i) => {
            return <Col
              key={i}
              sm={12}
              className="font-weight-light mt-2"
              dangerouslySetInnerHTML={{ __html: node.html }}
            />
          })}

        </Row>
      </Container>
    </Layout>
  )
}

export const resumePageQuery = graphql`
  query ResumePage {
    academics: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(data\/resume\/academics)/"  }}
      sort: {fields:frontmatter___endedAt order: DESC}
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            endedAt(formatString: "MMMM YYYY")
          }
        }
      }
    }

    activities: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(data\/resume\/activities)/"  }}
      sort: {fields:frontmatter___startedAt order: DESC}
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            startedAt(formatString: "YYYY")
            endedAt(formatString: "YYYY")
          }
        }
      }
    }

    skills: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(data\/resume\/skills)/"  }}
      sort: {fields:frontmatter___startedAt order: DESC}
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            startedAt(formatString: "YYYY")
            endedAt(formatString: "YYYY")
          }
        }
      }
    }

    experience: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(data\/resume\/experience)/"  }}
      sort: {fields:frontmatter___startedAt order: DESC}
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            position
            startedAt(formatString: "MMMM Do, YYYY")
            endedAt(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  }
`
